import React, { useState, useEffect, useCallback, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

import ClipLoader from "react-spinners/ClipLoader";

import CustomPopup from "../../components/CustomPopup.js";
import { MainFlowContext } from "../../context/FlowContext.js";
import useCookie from "../../utils/functions/gesCookie.js";
import pageVisitTracker from "../../utils/hooks/VisitTracker.js";

const ScanPage = () => {
  const [urlScanned, setUrlScanned] = useState("");
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Cherchez un Qr Code");
  const [successPop, setSuccessPop] = useState(false);
  const [failedPop, setFailedPop] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
/*   const [scanCount, setScanCount] = useState(0); */
  const [lastScannedUrl, setLastScannedUrl] = useState("");
/*   const [isQrReaderEnabled, setIsQrReaderEnabled] = useState(true); */
  const qrReaderRef = useRef(null);

/*   const [showLimitPopup, setShowLimitPopup] = useState(false); */

  const navigate = useNavigate();

  const Url_Valide = (UrlTest) => {
    var regexp = /((http(s)?:\/\/)?(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|\/redirect\/[a-zA-Z0-9]+)/ig;
    return regexp.test(UrlTest);
  };

  // Fonction pour vérifier si les 24 heures sont écoulées
  /* const isCooldownOver = () => {
    const cooldownTimestamp = parseInt(localStorage.getItem("cooldownTimestamp"), 10);
    if (!cooldownTimestamp) return true; // Pas de cooldown, c'est OK
  
    const now = Date.now();
    const diff = now - cooldownTimestamp;
    return diff >= 24 * 60 * 60 * 1000; // Vérifier si 24h sont écoulées
  };

  useEffect(() => {
    const savedCount = localStorage.getItem("scanCount");
    if (savedCount) {
      setScanCount(parseInt(savedCount));
    }
  
    // Si un timestamp est enregistré et les 24 heures sont écoulées, réinitialiser le compteur
    const cooldownTimestamp = localStorage.getItem("cooldownTimestamp");
    if (cooldownTimestamp) {
      if (isCooldownOver()) {
        // Si 24 heures sont écoulées, reset le compteur
        localStorage.removeItem("cooldownTimestamp");
        setScanCount(0);
        localStorage.setItem("scanCount", "0");
      } else {
        // Sinon, désactiver le QR reader
        setIsQrReaderEnabled(false);
      }
    }
  }, []);

  useEffect(() => {
    if (scanCount >= 5) {
      setShowLimitPopup(true);
      setIsQrReaderEnabled(false);
  
      // Enregistrer le timestamp des 24 heures
      if (!localStorage.getItem("cooldownTimestamp")) {
        localStorage.setItem("cooldownTimestamp", Date.now().toString());
      }
    }
  }, [scanCount]);

  const updateScanCount = useCallback(() => {
    setScanCount((prevCount) => {
      const newCount = prevCount + 1;
      localStorage.setItem("scanCount", newCount.toString());
      return newCount;
    });
  }, []);
 */
/*
  useEffect(() => {
    SetStat();
  }, []);
 */

  useEffect(() => {
    if (!loading) {
      setTitle("Cherchez un Qr Code");
    }
}, [loading]);

  const handleErrorScan = (result, error) => {
    if (result /* && isQrReaderEnabled && scanCount < 5 */) {
      setTitle("Scan en cours...");
      setLoading(false);

      if (result.text !== lastScannedUrl) {
        setUrlScanned(result.text);
        setLastScannedUrl(result.text);

        if (Url_Valide(result.text)) {
          setSuccessPop(true);
          setFailedPop(false);
         /*  updateScanCount(); */
        } else {
          setFailedPop(true);
          setSuccessPop(false);
          setWarningMsg("Assurez-vous de scanner un QrCode ScanGift");
        }
        /* setIsQrReaderEnabled(false); */
      }
    }
    if (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setSuccessPop(false);
    setFailedPop(false);
    setWarningMsg("");
    /* if (scanCount < 5) {
      setIsQrReaderEnabled(true);
    }
    if (showLimitPopup) {
      setShowLimitPopup(false);
      navigate("/decompte");
    } */
  };

  useEffect(() => {
    pageVisitTracker()

    return () => {
      /* console.log('clean') */
    };
  }, []);

  return (
    <div>
      <div className="card text-center bg-transparent border-0">
        <div className="card-header bg-transparent border-0">
          <h3 className="title-s-1">{title}</h3>
        </div>

        <div id="reader" className="card-body d-flex flex-column justify-content-center align-items-center my-2">
            <QrReader
              ref={qrReaderRef}
              scanDelay={500}
              onResult={handleErrorScan}
              className="qrReader"
              constraints={{ facingMode: "environment" }}
            />
        </div>
        <div className="scan-loader">
          <ClipLoader
            loading={loading}
            size={80}
            aria-label="Scanne en cours"
          />
        </div>

        <div className="card-footer bg-transparent border-0">
          <div className="btn-go">
            <div className="btn-circle-go">
              <p className="text-go color-warning">
                {warningMsg} 
              </p>
            </div>
          </div>
        </div>

        {/* <div className="scan-count">
          <p>Nombre de scans : {scanCount}</p>
        </div> */}

        {/* <Popup open={showLimitPopup} closeOnDocumentClick onClose={closeModal}>
  <div className="modal-popup">
    <div className="popup-content">
      <div className="popup-image">
        <img src={limite} alt="Limite atteinte" />
      </div>
      <div className="popup-text">
        <h2>PETITE INFO</h2>
        <p>Tu viens d'atteindre la limite de 5 scans.</p>
        <button onClick={closeModal} className="popup-btn">
          Compris
        </button>
      </div>
    </div>
  </div>
</Popup> */}


        <Popup open={successPop} closeOnDocumentClick onClose={closeModal}>
          <span className="close" type='button' onClick={closeModal}>
            &times;
          </span>

          <div className="modal"></div>

          <p className="content">Je tente ma chance !</p>
          <div className="actions d-flex justify-content-end">
            <span className='text-right'>
            <button
              className="btn-primary"
              onClick={() => {
                var nUrl = new URL(urlScanned);
                if (nUrl.pathname.startsWith('/redirect')) {
                  navigate(nUrl.pathname);
                } else {
                  navigate(`/redirect${nUrl.pathname}`);
                }
              }}
            >
                Okay
            </button>
            </span>
          </div>
        </Popup>

        <Popup open={failedPop} closeOnDocumentClick onClose={closeModal}>
          <span className="close" type='button' onClick={closeModal}>
            &times;
          </span>
          <div className="modal"></div>
          <p className="content">QrCode Non Valide !</p>
          <div className="actions d-flex justify-content-end">
          <a type="button" className='text-right' target="_blank" rel='noreferrer'>
            <button
              className="btn-danger"
              onClick={closeModal}
            >
              Okay
            </button>
            </a>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default ScanPage;
